/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";
import "../styles/IndexView.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61a285c4768b846d1d4586c2"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

class IndexView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqPanelExpanded: "",
    };

    this.homeRef = React.createRef();
    this.aboutRef = React.createRef();
  }

  handleChange = (panel) => (event, isExpanded) => {
    var currentPanel = this.state.faqPanelExpanded;
    if (panel === currentPanel) {
      this.setState({ faqPanelExpanded: "" });
    } else {
      this.setState({ faqPanelExpanded: panel });
    }
  };

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "61a285c4768b8480a24586cc";
    htmlEl.dataset["wfSite"] = "61a285c4768b846d1d4586c2";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          try {
            new Function(`
            with (this) {
              eval(arguments[0])
            }
          `).call(window, script);
          } catch (e) {
            console.log("error");
          }

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  generateNFTItems() {
    var files = [
      "1.png",
      "10.png",
      "14.png",
      "15.png",
      "16.png",
      "17.png",
      "18.png",
      "19.png",
      "2.png",
      "20.png",
      "21.png",
      "22.png",
      "23.png",
      "24.png",
      "25.png",
      "27.png",
      "3.png",
      "31.png",
      "39.png",
      "4.png",
      "40.png",
      "49.png",
      "5.png",
      "55.png",
      "6.png",
      "61.png",
      "7.png",
      "8.png",
      "83.png",
      "84.png",
      "9.png",
      "97.png",
      "99.png",
    ];

    var items = [];
    files.forEach((fileName) => {
      var path = "/images/" + fileName;
      items.push(
        <div className="hero-nft-gallery-item">
          <img src={path} />
        </div>
      );
    });

    return items;
  }

  render() {
    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplaySpeed: 1500,
      autoplay: true,
      slidesToShow: 6,
      speed: 500,
      swipeToSlide: true,
    };

    return (
      <span>
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <div
                data-collapse="medium"
                data-animation="default"
                data-duration={400}
                data-w-id="58db7844-5919-d71b-dd74-2323ed8dffe9"
                data-easing="ease"
                data-easing2="ease"
                role="banner"
                className="af-class-header w-nav"
              >
                <div className="af-class-container-header">
                  <div className="af-class-split-content af-class-header-left">
                    <a
                      href="javascript:void(0);"
                      aria-current="page"
                      className="af-class-brand-logo w-nav-brand w--current"
                    >
                      <img
                        src="images/logo-nft-webflow-template.png"
                        alt
                        className="af-class-header-logo"
                      />
                    </a>
                  </div>
                  <div className="af-class-nav-container">
                    <nav
                      role="navigation"
                      className="af-class-nav-menu w-nav-menu"
                    >
                      <ul role="list" className="af-class-header-navigation">
                        <li className="af-class-nav-item-wrapper">
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              this.homeRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                              })
                            }
                            aria-current="page"
                            className="af-class-nav-link w--current"
                          >
                            Home
                          </a>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              this.aboutRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                              })
                            }
                            className="af-class-nav-link"
                          >
                            Roadmap
                          </a>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <a href="store.html" className="af-class-nav-link">
                            Whitelist
                          </a>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <Link className="af-class-nav-link" to="/rarity">
                            Rarity
                          </Link>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <div
                            data-hover="false"
                            data-delay={0}
                            data-w-id="45c82122-ac7e-c995-1427-8593ed75fc00"
                            className="af-class-header-dropdown w-dropdown"
                          ></div>
                        </li>
                        <li className="af-class-nav-item-wrapper af-class-button">
                          <div className="af-class-button-container af-class-full-width">
                            <a
                              href="https://nfttemplate.webflow.io/#Store"
                              className="af-class-button-primary af-class-small af-class-full-width w-button"
                            >
                              Mint
                            </a>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="af-class-split-content af-class-header-right">
                    <div className="af-class-hidden-tablet">
                      <div className="af-class-button-container">
                        <a
                          href="https://nfttemplate.webflow.io/#store"
                          className="af-class-button-primary af-class-small w-button"
                        >
                          Mint
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-w-id="4eabff81-1a43-f11f-339b-2d46f64fc52a"
                className="af-class-section af-class-hero-home af-class-wf-section"
              >
                <div className="af-class-container-default w-container">
                  <div className="w-layout-grid af-class-hero-grid">
                    <div
                      ref={this.homeRef}
                      data-w-id="bc03214d-5378-fb49-67b8-1af4a8eae15c"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        opacity: 0,
                      }}
                    >
                      <h1 className="hero-text">Vicious Vampires</h1>
                      <p className="af-class-paragraph-large">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tesfsmpoasfr incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                      <div className="af-class-mg-top-48px">
                        <div className="af-class-button-container">
                          <a
                            href="#store"
                            className="af-class-button-primary w-button"
                          >
                            Mint
                          </a>
                        </div>
                      </div>
                    </div>

                    <img
                      src="/images/test.png"
                      data-w-id="e5a52d42-0186-1e21-b84e-a2707429aefc"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        opacity: 0,
                      }}
                      className="hero-picture"
                    />
                  </div>
                </div>
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  data-w-id="e5a52d42-0186-1e21-b84e-a2707429aefc"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  alt
                  className="af-class-blob-home-hero"
                />
                {/* <img
                  src="images/bubble-1-nft-webflow-template.png"
                  loading="lazy"
                  width="368.5"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  data-w-id="1250e44f-4ea4-8cbb-6009-8ef4f74ffe28"
                  alt
                  className="af-class-bubble af-class-hero-home"
                /> */}
                {/* <img
                  src="images/donut-nft-webflow-template.png"
                  data-w-id="554897b1-25e1-e3a4-3605-133d21b38602"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  alt
                  className="af-class-donuts af-class-hero-home"
                /> */}
              </div>

              <div
                id="about"
                data-w-id="459cd8d6-6bd6-c406-d0f4-2fb0365f9af8"
                className="af-class-section af-class-shop-home af-class-wf-section about-me-section"
              >
                <div className="af-class-container-default w-container">
                  <div className="">
                    <div
                      ref={this.aboutRef}
                      data-w-id="691ff07f-40ad-a418-76a1-adb1e6a6e7b5"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        opacity: 0,
                      }}
                    >
                      <h2>Roadmap</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Tincidunt magna non, libero turpis sem vitae et. Natoque
                        ut egestas sit volutpat.
                      </p>
                      <ul
                        role="list"
                        className="af-class-list-gradient-container"
                      >
                        <li className="af-class-list-item-gradient">
                          <div>Illustration Artist</div>
                        </li>
                        <li className="af-class-list-item-gradient">
                          <div>3D Modeler</div>
                        </li>
                      </ul>
                      <VerticalTimeline>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          contentStyle={{
                            background: "rgb(33, 150, 243)",
                            color: "#fff",
                          }}
                          contentArrowStyle={{
                            borderRight: "7px solid  rgb(33, 150, 243)",
                          }}
                          date="Solanalysis First Moonshot Project"
                          iconStyle={{
                            background: "rgb(33, 150, 243)",
                            color: "#fff",
                          }}
                          // icon={<WorkIcon />}
                        >
                          <h3 className="vertical-timeline-element-title">
                            Creative Director
                          </h3>
                          <h4 className="vertical-timeline-element-subtitle">
                            Miami, FL
                          </h4>
                          <p>
                            Creative Direction, User Experience, Visual Design,
                            Project Management, Team Leading
                          </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          date="Giveaways"
                          iconStyle={{
                            background: "rgb(33, 150, 243)",
                            color: "#fff",
                          }}
                          // icon={<WorkIcon />}
                        >
                          <h3 className="vertical-timeline-element-title">
                            Art Director
                          </h3>
                          <h4 className="vertical-timeline-element-subtitle">
                            San Francisco, CA
                          </h4>
                          <p>
                            Creative Direction, User Experience, Visual Design,
                            SEO, Online Marketing
                          </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          contentStyle={{
                            background: "rgb(142, 150, 11)",
                            color: "#fff",
                          }}
                          className="vertical-timeline-element--education"
                          date="Whitelist"
                          iconStyle={{
                            background: "rgb(233, 30, 99)",
                            color: "#fff",
                          }}
                          // icon={<WorkIcon />}
                        >
                          <h3 className="vertical-timeline-element-title">
                            Web Designer
                          </h3>
                          <h4 className="vertical-timeline-element-subtitle">
                            Los Angeles, CA
                          </h4>
                          <p>User Experience, Visual Design</p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          className="vertical-timeline-element--education"
                          date="Mint #1"
                          iconStyle={{
                            background: "rgb(33, 150, 243)",
                            color: "#fff",
                          }}
                          // icon={<WorkIcon />}
                        >
                          <h3 className="vertical-timeline-element-title">
                            Web Designer
                          </h3>
                          <h4 className="vertical-timeline-element-subtitle">
                            Los Angeles, CA
                          </h4>
                          <p>User Experience, Visual Design</p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                          iconStyle={{
                            background: "rgb(16, 204, 82)",
                            color: "#fff",
                          }}
                          // icon={<StarIcon />}
                        />
                      </VerticalTimeline>
                    </div>
                  </div>
                </div>
                {/* <img
                  src="images/donut-nft-webflow-template.png"
                  loading="lazy"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  data-w-id="172a4418-1d22-b593-830b-2fdcda5e3d8e"
                  alt
                  className="af-class-donuts af-class-about-home"
                /> */}
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  alt
                  className="af-class-blob-home-about"
                />
              </div>

              <div
                id="store"
                data-w-id="cc85c299-03d7-156e-0385-e31410ae0102"
                className="af-class-section af-class-shop-home af-class-wf-section nft-gallery-section"
              >
                <div className="hero-nft-slider-container">
                  <div
                    data-w-id="a7b9cdbe-a4d9-b41f-971c-ca92967cbec6"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                    className="af-class-mg-bottom-58px"
                  >
                    <div className="af-class-container-medium-730px">
                      <h2>NFT Gallery</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Tincidunt magna non, libero turpis sem vitae et. Natoque
                        ut egestas sit volutpat etiam enim. Adipiscing rhoncus.
                      </p>
                    </div>
                  </div>

                  <div className="hero-nft-gallery-container">
                    <Slider {...settings}>{this.generateNFTItems()}</Slider>
                  </div>
                </div>
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  alt
                  className="af-class-blob-home-gallery-one"
                />
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  alt
                  className="af-class-blob-home-gallery-two"
                />
              </div>

              <div
                id="work"
                className="af-class-section af-class-my-work af-class-wf-section"
              >
                <div className="af-class-container-default w-container">
                  <div
                    data-w-id="f724c142-e2d0-0a9b-ead3-f4893760af6c"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                    className="af-class-top-content"
                  >
                    <h2 className="af-class-mg-bottom-0">The Team</h2>
                    <p className="af-class-work-paragraph-top">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tincidunt magna non, libero turpis sem vitae et
                    </p>
                  </div>

                  <div className="team-container">
                    <div className="team-item">
                      <img src="/images/99.png" />
                      <h5>Beard Guy</h5>
                      <h6>Developer</h6>
                      <p>
                        This man is whack. This man is whack This man is whack
                        This man is whack This man is whack This man is whack
                      </p>
                    </div>
                    <div className="team-item">
                      <img src="/images/15.png" />
                      <h5>Beard Guy</h5>
                      <h6>Developer</h6>
                      <p>
                        This man is whack. This man is whack This man is whack
                        This man is whack This man is whack This man is whack
                      </p>
                    </div>
                    <div className="team-item">
                      <img src="/images/18.png" />
                      <h5>Beard Guy</h5>
                      <h6>Developer</h6>
                      <p>
                        This man is whack. This man is whack This man is whack
                        This man is whack This man is whack This man is whack
                      </p>
                    </div>
                    <div className="team-item">
                      <img src="/images/40.png" />
                      <h5>Beard Guy</h5>
                      <h6>Developer</h6>
                      <p>
                        This man is whack. This man is whack This man is whack
                        This man is whack This man is whack This man is whack
                      </p>
                    </div>
                    <div className="team-item">
                      <img src="/images/27.png" />
                      <h5>Beard Guy</h5>
                      <h6>Developer</h6>
                      <p>
                        This man is whack. This man is whack This man is whack
                        This man is whack This man is whack This man is whack
                      </p>
                    </div>
                  </div>

                  <div
                    className="faq-container"
                    ref={this.aboutRef}
                    data-w-id="691ff07f-40ad-a418-76a1-adb1e6a6e7b5"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                  >
                    <h2>FAQ</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tincidunt magna non, libero turpis sem vitae et. Natoque
                      ut egestas sit volutpat.
                    </p>

                    <div className="accordion-container">
                      <Accordion className="accordion-item">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              fontSize: "22px",
                              fontFamily: "Space Grotesk",
                              fontWeight: "bold",
                            }}
                          >
                            Accordion 1
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              fontFamily: "Space Grotesk",
                              color: "white",
                            }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="accordion-item">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography
                            sx={{
                              color: "white",
                              fontSize: "22px",
                              fontFamily: "Space Grotesk",
                              fontWeight: "bold",
                            }}
                          >
                            Accordion 2
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            sx={{
                              color: "text.secondary",
                              fontFamily: "Space Grotesk",
                              color: "white",
                            }}
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>

                {/* <img
                  src="images/blob-blur-my-work-nft-webflow-tempalte.svg"
                  data-w-id="270bdb76-810b-8881-d3ec-da63dc1a6097"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  alt
                  className="af-class-blob-home-my-work"
                /> */}

                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  loading="lazy"
                  alt
                  className="af-class-blob-home-contact-two"
                />
              </div>

              <footer
                data-w-id="1356e90d-e3a3-2953-9ae6-a897ae2f46c5"
                className="af-class-footer"
              >
                <div className="af-class-container-default">
                  <div className="af-class-footer-top">
                    <div className="af-class-footer-top-left">
                      <a
                        href="#0"
                        aria-current="page"
                        onClick={() =>
                          this.homeRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                          })
                        }
                        className="af-class-footer-logo-container w-inline-block w--current"
                      >
                        <img
                          src="images/logo-nft-webflow-template.png"
                          alt
                          className="af-class-footer-logo"
                        />
                      </a>
                      <ul role="list" className="af-class-footer-nav">
                        <li className="af-class-footer-nav-item">
                          <a
                            href="javascript:void(0);"
                            aria-current="page"
                            className="af-class-nav-link af-class-footer w--current"
                          >
                            Home
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="#about"
                            className="af-class-nav-link af-class-footer"
                          >
                            About
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="store.html"
                            className="af-class-nav-link af-class-footer"
                          >
                            Store
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="https://nfttemplate.webflow.io/#work"
                            className="af-class-nav-link af-class-footer"
                          >
                            Work
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="https://nfttemplate.webflow.io/#contact"
                            className="af-class-nav-link af-class-footer"
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <ul role="list" className="af-class-list-social-media">
                        <li className="af-class-list-item-social-media">
                          <a
                            href="http://facebook.com/"
                            className="af-class-social-media-icon af-class-facebook"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://twitter.com/"
                            className="af-class-social-media-icon"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://www.instagram.com/"
                            className="af-class-social-media-icon"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://www.linkedin.com/"
                            className="af-class-social-media-icon af-class-linkedin"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="http://youtube.com/"
                            className="af-class-social-media-icon af-class-youtube"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://www.whatsapp.com/"
                            className="af-class-social-media-icon"
                          >
                            
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="af-class-footer-bottom">
                    <p>
                      Copyright © Vicious Vampires | Designed by neode
                    </p>
                  </div>
                </div>
              </footer>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default IndexView;

/* eslint-enable */
