/* eslint-disable */

import React, { useState, useEffect } from "react";
import { createScope, map, transformProxies } from "./helpers";
import "../styles/IndexView.css";

import { Link } from "react-router-dom";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61a285c4768b846d1d4586c2"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class RarityView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.homeRef = React.createRef();
    this.aboutRef = React.createRef();
  }

  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("..controllers/IndexController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = IndexView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "61a285c4768b8480a24586cc";
    htmlEl.dataset["wfSite"] = "61a285c4768b846d1d4586c2";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(public\\css\\normalize.css);
          @import url(C:\\css\\webflow.css);
          @import url(../../public/css/brent-nft.webflow.css);
          @import url(C:\\Users\\B\\Desktop\\Development\\SolanaNFT\\newNFT\\testing\\public\\css\\brent-nft.webflow.css);
        `,
          }}
        />
        yeeeeeeeeeeeeeeeeeeeet
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <div
                data-collapse="medium"
                data-animation="default"
                data-duration={400}
                data-w-id="58db7844-5919-d71b-dd74-2323ed8dffe9"
                data-easing="ease"
                data-easing2="ease"
                role="banner"
                className="af-class-header w-nav"
              >
                <div className="af-class-container-header">
                  <div className="af-class-split-content af-class-header-left">
                    <a
                      href="javascript:void(0);"
                      aria-current="page"
                      className="af-class-brand-logo w-nav-brand w--current"
                    >
                      <img
                        src="images/logo-nft-webflow-template.png"
                        alt
                        className="af-class-header-logo"
                      />
                    </a>
                  </div>
                  <div className="af-class-nav-container">
                    <nav
                      role="navigation"
                      className="af-class-nav-menu w-nav-menu"
                    >
                      <ul role="list" className="af-class-header-navigation">
                        <li className="af-class-nav-item-wrapper">
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              this.homeRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                              })
                            }
                            aria-current="page"
                            className="af-class-nav-link w--current"
                          >
                            Home
                          </a>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              this.aboutRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                              })
                            }
                            className="af-class-nav-link"
                          >
                            Team
                          </a>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <a href="store.html" className="af-class-nav-link">
                            Whitelist
                          </a>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <Link className="af-class-nav-link" to="/rarity">
                            Rarity
                          </Link>
                        </li>
                        <li className="af-class-nav-item-wrapper">
                          <div
                            data-hover="false"
                            data-delay={0}
                            data-w-id="45c82122-ac7e-c995-1427-8593ed75fc00"
                            className="af-class-header-dropdown w-dropdown"
                          ></div>
                        </li>
                        <li className="af-class-nav-item-wrapper af-class-button">
                          <div className="af-class-button-container af-class-full-width">
                            <a
                              href="https://nfttemplate.webflow.io/#Store"
                              className="af-class-button-primary af-class-small af-class-full-width w-button"
                            >
                              Browse Gallery
                            </a>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="af-class-split-content af-class-header-right">
                    <div className="af-class-mobile-menu">
                      <div className="af-class-menu-button w-nav-button">
                        <div className="af-class-menu-button-icon-wrapper">
                          <div className="af-class-menu-button-icon">
                            <div className="af-class-menu-line-top" />
                            <div className="af-class-menu-line-middle" />
                            <div className="af-class-menu-line-bottom" />
                          </div>
                        </div>
                      </div>
                      <ul role="list" className="af-class-cart-mobile">
                        <li className="af-class-nav-item-wrapper af-class-cart">
                          <div
                            data-node-type="commerce-cart-wrapper"
                            data-open-product
                            data-wf-cart-type="modal"
                            data-wf-cart-query
                            data-wf-page-link-href-prefix
                            className="w-commerce-commercecartwrapper af-class-cart"
                          >
                            <a
                              href="#"
                              data-node-type="commerce-cart-open-link"
                              className="w-commerce-commercecartopenlink af-class-cart-button w-inline-block"
                            >
                              <div className="w-inline-block">Cart</div>
                              <div className="w-inline-block">(</div>
                              <div className="w-commerce-commercecartopenlinkcount af-class-cart-quantity">
                                0
                              </div>
                              <div className="w-inline-block">)</div>
                            </a>
                            <div
                              data-node-type="commerce-cart-container-wrapper"
                              style={{ display: "none" }}
                              className="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-modal af-class-cart-wrapper"
                            >
                              <div
                                data-node-type="commerce-cart-container"
                                className="w-commerce-commercecartcontainer af-class-cart-container"
                              >
                                <div className="w-commerce-commercecartheader af-class-cart-header">
                                  <h4 className="w-commerce-commercecartheading af-class-cart-title">
                                    Your Cart
                                  </h4>
                                  <a
                                    href="#"
                                    data-node-type="commerce-cart-close-link"
                                    className="w-commerce-commercecartcloselink af-class-close-button-cart w-inline-block"
                                  />
                                </div>
                                <div className="w-commerce-commercecartformwrapper">
                                  <form
                                    data-node-type="commerce-cart-form"
                                    style={{ display: "none" }}
                                    className="w-commerce-commercecartform"
                                  >
                                    <div
                                      className="w-commerce-commercecartlist"
                                      data-wf-collection="database.commerceOrder.userItems"
                                      data-wf-template-id="wf-template-f20819fc-7019-7cea-2eb5-a221eccd61d1"
                                    />
                                    <div className="w-commerce-commercecartfooter af-class-cart-footer">
                                      <div className="w-commerce-commercecartlineitem">
                                        <div>Subtotal</div>
                                        <div className="w-commerce-commercecartordervalue af-class-cart-subtotal" />
                                      </div>
                                      <div>
                                        <div data-node-type="commerce-cart-quick-checkout-actions">
                                          <a
                                            data-node-type="commerce-cart-apple-pay-button"
                                            style={{
                                              backgroundImage:
                                                "-webkit-named-image(apple-pay-logo-white)",
                                              backgroundSize: "100% 50%",
                                              backgroundPosition: "50% 50%",
                                              backgroundRepeat: "no-repeat",
                                            }}
                                            className="w-commerce-commercecartapplepaybutton af-class-apple-pay"
                                          >
                                            <div />
                                          </a>
                                          <a
                                            data-node-type="commerce-cart-quick-checkout-button"
                                            style={{ display: "none" }}
                                            className="w-commerce-commercecartquickcheckoutbutton"
                                          >
                                            <svg
                                              className="w-commerce-commercequickcheckoutgoogleicon"
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlnsXlink="http://www.w3.org/1999/xlink"
                                              width={16}
                                              height={16}
                                              viewBox="0 0 16 16"
                                            >
                                              <defs>
                                                <polygon
                                                  id="google-mark-a"
                                                  points="0 .329 3.494 .329 3.494 7.649 0 7.649"
                                                />
                                                <polygon
                                                  id="google-mark-c"
                                                  points=".894 0 13.169 0 13.169 6.443 .894 6.443"
                                                />
                                              </defs>
                                              <g fill="none" fillRule="evenodd">
                                                <path
                                                  fill="#4285F4"
                                                  d="M10.5967,12.0469 L10.5967,14.0649 L13.1167,14.0649 C14.6047,12.6759 15.4577,10.6209 15.4577,8.1779 C15.4577,7.6339 15.4137,7.0889 15.3257,6.5559 L7.8887,6.5559 L7.8887,9.6329 L12.1507,9.6329 C11.9767,10.6119 11.4147,11.4899 10.5967,12.0469"
                                                />
                                                <path
                                                  fill="#34A853"
                                                  d="M7.8887,16 C10.0137,16 11.8107,15.289 13.1147,14.067 C13.1147,14.066 13.1157,14.065 13.1167,14.064 L10.5967,12.047 C10.5877,12.053 10.5807,12.061 10.5727,12.067 C9.8607,12.556 8.9507,12.833 7.8887,12.833 C5.8577,12.833 4.1387,11.457 3.4937,9.605 L0.8747,9.605 L0.8747,11.648 C2.2197,14.319 4.9287,16 7.8887,16"
                                                />
                                                <g transform="translate(0 4)">
                                                  <mask
                                                    id="google-mark-b"
                                                    fill="#fff"
                                                  >
                                                    <use xlinkHref="#google-mark-a" />
                                                  </mask>
                                                  <path
                                                    fill="#FBBC04"
                                                    d="M3.4639,5.5337 C3.1369,4.5477 3.1359,3.4727 3.4609,2.4757 L3.4639,2.4777 C3.4679,2.4657 3.4749,2.4547 3.4789,2.4427 L3.4939,0.3287 L0.8939,0.3287 C0.8799,0.3577 0.8599,0.3827 0.8459,0.4117 C-0.2821,2.6667 -0.2821,5.3337 0.8459,7.5887 L0.8459,7.5997 C0.8549,7.6167 0.8659,7.6317 0.8749,7.6487 L3.4939,5.6057 C3.4849,5.5807 3.4729,5.5587 3.4639,5.5337"
                                                    mask="url(#google-mark-b)"
                                                  />
                                                </g>
                                                <mask
                                                  id="google-mark-d"
                                                  fill="#fff"
                                                >
                                                  <use xlinkHref="#google-mark-c" />
                                                </mask>
                                                <path
                                                  fill="#EA4335"
                                                  d="M0.894,4.3291 L3.478,6.4431 C4.113,4.5611 5.843,3.1671 7.889,3.1671 C9.018,3.1451 10.102,3.5781 10.912,4.3671 L13.169,2.0781 C11.733,0.7231 9.85,-0.0219 7.889,0.0001 C4.941,0.0001 2.245,1.6791 0.894,4.3291"
                                                  mask="url(#google-mark-d)"
                                                />
                                              </g>
                                            </svg>
                                            <svg
                                              className="w-commerce-commercequickcheckoutmicrosofticon"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={16}
                                              height={16}
                                              viewBox="0 0 16 16"
                                            >
                                              <g fill="none" fillRule="evenodd">
                                                <polygon
                                                  fill="#F05022"
                                                  points="7 7 1 7 1 1 7 1"
                                                />
                                                <polygon
                                                  fill="#7DB902"
                                                  points="15 7 9 7 9 1 15 1"
                                                />
                                                <polygon
                                                  fill="#00A4EE"
                                                  points="7 15 1 15 1 9 7 9"
                                                />
                                                <polygon
                                                  fill="#FFB700"
                                                  points="15 15 9 15 9 9 15 9"
                                                />
                                              </g>
                                            </svg>
                                            <div>Pay with browser.</div>
                                          </a>
                                        </div>
                                        <div className="af-class-button-container af-class-full-width af-class-mg-top-16px">
                                          <a
                                            href="checkout.html"
                                            value="Continue to Checkout"
                                            data-node-type="cart-checkout-button"
                                            className="w-commerce-commercecartcheckoutbutton af-class-button-primary af-class-small"
                                            data-loading-text="Hang Tight..."
                                          >
                                            Continue to Checkout
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                  <div className="w-commerce-commercecartemptystate af-class-empty-state">
                                    <div>No items found.</div>
                                  </div>
                                  <div
                                    style={{ display: "none" }}
                                    data-node-type="commerce-cart-error"
                                    className="w-commerce-commercecarterrorstate af-class-error-message"
                                  >
                                    <div
                                      className="w-cart-error-msg"
                                      data-w-cart-quantity-error="Product is not available in this quantity."
                                      data-w-cart-general-error="Something went wrong when adding this item to the cart."
                                      data-w-cart-checkout-error="Checkout is disabled on this site."
                                      data-w-cart-cart_order_min-error="The order minimum was not met. Add more items to your cart to continue."
                                      data-w-cart-subscription_error-error="Cart failed."
                                    >
                                      Product is not available in this quantity.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="af-class-hidden-tablet">
                      <div className="af-class-button-container">
                        <a
                          href="https://nfttemplate.webflow.io/#store"
                          className="af-class-button-primary af-class-small w-button"
                        >
                          Browse gallery
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="4eabff81-1a43-f11f-339b-2d46f64fc52a"
                className="af-class-section af-class-hero-home af-class-wf-section"
              >
                <div className="af-class-container-default w-container">
                  <div className="w-layout-grid af-class-hero-grid">
                    <div
                      ref={this.homeRef}
                      data-w-id="bc03214d-5378-fb49-67b8-1af4a8eae15c"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        opacity: 0,
                      }}
                    >
                      <h1>I'm John Carter, a NFT Artist</h1>
                      <p className="af-class-paragraph-large">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vel purus cras curabitur eget facilisis nisl. Neque a
                        porttitor .
                      </p>
                      <div className="af-class-mg-top-48px">
                        <div className="af-class-button-container">
                          <a
                            href="#store"
                            className="af-class-button-primary w-button"
                          >
                            Browse gallery
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  data-w-id="e5a52d42-0186-1e21-b84e-a2707429aefc"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  alt
                  className="af-class-blob-home-hero"
                />
                <img
                  src="images/bubble-1-nft-webflow-template.png"
                  loading="lazy"
                  width="368.5"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  data-w-id="1250e44f-4ea4-8cbb-6009-8ef4f74ffe28"
                  alt
                  className="af-class-bubble af-class-hero-home"
                />
                <img
                  src="images/donut-nft-webflow-template.png"
                  data-w-id="554897b1-25e1-e3a4-3605-133d21b38602"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  alt
                  className="af-class-donuts af-class-hero-home"
                />
              </div>
              <div
                id="about"
                data-w-id="459cd8d6-6bd6-c406-d0f4-2fb0365f9af8"
                className="af-class-section af-class-shop-home af-class-wf-section"
              >
                <div className="af-class-container-default w-container">
                  <div className="w-layout-grid af-class-grid-about">
                    <div className="af-class-image-about-me-container">
                      <img
                        src="images/bubble-2-nft-webflow-template-1.png"
                        loading="lazy"
                        width="368.5"
                        style={{
                          WebkitTransform:
                            "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                          MozTransform:
                            "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                          msTransform:
                            "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                          transform:
                            "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                          opacity: 0,
                        }}
                        data-w-id="9dd1eb0d-4574-d2ec-03a3-f5e233c8efd4"
                        alt
                        className="af-class-bubble af-class-about-me"
                      />
                      <img
                        src="images/about-me-nft-webflow-template.jpeg"
                        srcSet="images/about-me-nft-webflow-template-p-500.jpeg 500w, images/about-me-nft-webflow-template-p-800.jpeg 800w, images/about-me-nft-webflow-template-p-1080.jpeg 1080w, images/about-me-nft-webflow-template.jpeg 1090w"
                        style={{
                          WebkitTransform:
                            "translate3d(62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(19deg) skew(0, 0)",
                          MozTransform:
                            "translate3d(62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(19deg) skew(0, 0)",
                          msTransform:
                            "translate3d(62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(19deg) skew(0, 0)",
                          transform:
                            "translate3d(62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(19deg) skew(0, 0)",
                          opacity: 0,
                        }}
                        sizes="(max-width: 479px) 82vw, (max-width: 767px) 85vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 1186.077880859375px, 62vw"
                        alt
                        className="af-class-image-about-me"
                      />
                      <div
                        style={{
                          opacity: 0,
                          WebkitTransform:
                            "translate3d(-62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(-19deg) skew(0, 0)",
                          MozTransform:
                            "translate3d(-62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(-19deg) skew(0, 0)",
                          msTransform:
                            "translate3d(-62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(-19deg) skew(0, 0)",
                          transform:
                            "translate3d(-62px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(-19deg) skew(0, 0)",
                        }}
                        className="af-class-gradient-bottom-about"
                      />
                    </div>
                    <div
                      ref={this.aboutRef}
                      data-w-id="691ff07f-40ad-a418-76a1-adb1e6a6e7b5"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        opacity: 0,
                      }}
                    >
                      <h2>About me</h2>
                      <p className="af-class-paragraph-large">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Tincidunt magna non, libero turpis sem vitae et. Natoque
                        ut egestas sit volutpat.
                      </p>
                      <ul
                        role="list"
                        className="af-class-list-gradient-container"
                      >
                        <li className="af-class-list-item-gradient">
                          <div>Illustration Artist</div>
                        </li>
                        <li className="af-class-list-item-gradient">
                          <div>3D Modeler</div>
                        </li>
                      </ul>
                      <div className="af-class-mg-top-48px">
                        <div className="af-class-button-container">
                          <a
                            href="#contact"
                            className="af-class-button-primary w-button"
                          >
                            Hire me
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src="images/donut-nft-webflow-template.png"
                  loading="lazy"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  data-w-id="172a4418-1d22-b593-830b-2fdcda5e3d8e"
                  alt
                  className="af-class-donuts af-class-about-home"
                />
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  alt
                  className="af-class-blob-home-about"
                />
              </div>
              <div
                id="store"
                data-w-id="cc85c299-03d7-156e-0385-e31410ae0102"
                className="af-class-section af-class-shop-home af-class-wf-section"
              >
                <div className="af-class-container-default w-container">
                  <div
                    data-w-id="a7b9cdbe-a4d9-b41f-971c-ca92967cbec6"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                    className="af-class-mg-bottom-58px"
                  >
                    <div className="af-class-container-medium-730px">
                      <div className="af-class-text-center">
                        <h2>NFT Gallery</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Tincidunt magna non, libero turpis sem vitae et.
                          Natoque ut egestas sit volutpat etiam enim. Adipiscing
                          rhoncus.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-id="b7b6555e-a7b8-f35c-ae3d-c161983d3e16"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                    className="w-dyn-list"
                  >
                    <div
                      role="list"
                      className="af-class-products-container w-dyn-items"
                    >
                      <div role="listitem" className="w-dyn-item">
                        <a
                          href="#"
                          className="af-class-product-card-image w-inline-block"
                        >
                          <img src alt />
                        </a>
                        <div className="af-class-product-card-pricing" />
                        <a
                          href="#"
                          className="af-class-link-product-container w-inline-block"
                        >
                          <h3 className="af-class-link-product" />
                        </a>
                        <p />
                        <a
                          data-w-id="c48a718e-1a62-55bc-f080-ef674e532b49"
                          href="#"
                          className="af-class-link-wrapper af-class-learn-more-product w-inline-block"
                        >
                          <div>View NFT</div>
                          <div className="af-class-underline-wrapper">
                            <div
                              style={{ width: 0 }}
                              className="af-class-underline"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="af-class-card-crystal af-class-empty-state w-dyn-empty">
                      <div>No items found.</div>
                    </div>
                  </div>
                </div>
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  alt
                  className="af-class-blob-home-gallery-one"
                />
                <img
                  src="images/blur-blob-nft-webflow-template.svg"
                  alt
                  className="af-class-blob-home-gallery-two"
                />
              </div>
              <div
                id="work"
                className="af-class-section af-class-my-work af-class-wf-section"
              >
                <div className="af-class-container-default w-container">
                  <div
                    data-w-id="f724c142-e2d0-0a9b-ead3-f4893760af6c"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                    className="af-class-top-content"
                  >
                    <h2 className="af-class-mg-bottom-0">My work</h2>
                    <p className="af-class-work-paragraph-top">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Tincidunt magna non, libero turpis sem vitae et
                    </p>
                  </div>
                  <div
                    data-w-id="7469260d-8b28-4036-529d-32eb66383187"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: 0,
                    }}
                    className="w-layout-grid af-class-grid-my-work"
                  >
                    <div className="af-class-card-crystal">
                      <h3>Digital illustration</h3>
                      <p className="af-class-paragraph-large">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut ornare diam nulla mi. Ac tortor consectetur nunc orci
                        gravid.
                      </p>
                    </div>
                    <div className="af-class-card-crystal">
                      <h3>3D modeling</h3>
                      <p className="af-class-paragraph-large">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut ornare diam nulla mi. Ac tortor consectetur nunc orci
                        gravid.
                      </p>
                    </div>
                    <div className="af-class-card-crystal">
                      <h3>Motion graphics</h3>
                      <p className="af-class-paragraph-large">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut ornare diam nulla mi. Ac tortor consectetur nunc orci
                        gravid.
                      </p>
                    </div>
                    <div className="af-class-card-crystal">
                      <h3>Coding</h3>
                      <p className="af-class-paragraph-large">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut ornare diam nulla mi. Ac tortor consectetur nunc orci
                        gravid.
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  src="images/blob-blur-my-work-nft-webflow-tempalte.svg"
                  data-w-id="270bdb76-810b-8881-d3ec-da63dc1a6097"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0,
                  }}
                  alt
                  className="af-class-blob-home-my-work"
                />
              </div>


              <footer
                data-w-id="1356e90d-e3a3-2953-9ae6-a897ae2f46c5"
                className="af-class-footer"
              >
                <div className="af-class-container-default">
                  <div className="af-class-footer-top">
                    <div className="af-class-footer-top-left">
                      <a
                        href="#0"
                        aria-current="page"
                        onClick={() =>
                          this.homeRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                          })
                        }
                        className="af-class-footer-logo-container w-inline-block w--current"
                      >
                        <img
                          src="images/logo-nft-webflow-template.png"
                          alt
                          className="af-class-footer-logo"
                        />
                      </a>
                      <ul role="list" className="af-class-footer-nav">
                        <li className="af-class-footer-nav-item">
                          <a
                            href="javascript:void(0);"
                            aria-current="page"
                            className="af-class-nav-link af-class-footer w--current"
                          >
                            Home
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="#about"
                            className="af-class-nav-link af-class-footer"
                          >
                            About
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="store.html"
                            className="af-class-nav-link af-class-footer"
                          >
                            Store
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="https://nfttemplate.webflow.io/#work"
                            className="af-class-nav-link af-class-footer"
                          >
                            Work
                          </a>
                        </li>
                        <li className="af-class-footer-nav-item">
                          <a
                            href="https://nfttemplate.webflow.io/#contact"
                            className="af-class-nav-link af-class-footer"
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <ul role="list" className="af-class-list-social-media">
                        <li className="af-class-list-item-social-media">
                          <a
                            href="http://facebook.com/"
                            className="af-class-social-media-icon af-class-facebook"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://twitter.com/"
                            className="af-class-social-media-icon"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://www.instagram.com/"
                            className="af-class-social-media-icon"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://www.linkedin.com/"
                            className="af-class-social-media-icon af-class-linkedin"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="http://youtube.com/"
                            className="af-class-social-media-icon af-class-youtube"
                          >
                            
                          </a>
                        </li>
                        <li className="af-class-list-item-social-media">
                          <a
                            href="https://www.whatsapp.com/"
                            className="af-class-social-media-icon"
                          >
                            
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="af-class-footer-bottom">
                    <p>
                      Copyright © NFT | Designed by{" "}
                      <a
                        href="https://brixtemplates.com/"
                        className="af-class-link-white"
                      >
                        BRIX Templates
                      </a>{" "}
                      - Powered by{" "}
                      <a
                        href="https://webflow.com/"
                        className="af-class-link-white"
                      >
                        Webflow
                      </a>
                    </p>
                  </div>
                </div>
              </footer>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default RarityView;

/* eslint-enable */
