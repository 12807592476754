/* eslint-disable */

import React, { useState, useEffect } from "react";
import { createScope, map, transformProxies } from "./helpers";
import IndexView from "./IndexView";
import "../styles/LoadingIndex.css";


class LoadingIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0); //scroll top
      this.setState({ loading: false });
      window.scrollTo(0, 10);
    }, 15);
  }

  render() {

    if (this.state.loading) {
      return (
        <div class="loadingPage">
          Loading...
          <div class="loader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      );
    }

    return <IndexView />;
  }
}

export default LoadingIndex;
