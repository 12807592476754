import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import IndexView from "./IndexView";
import LoadingIndex from "./LoadingIndex";
import RarityView from "./RarityView";
import NightmareView from "./NightmareView";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoadingIndex />} />
        <Route exact path="/rarity" element={<RarityView />} />
        <Route exact path="/nightmare" element={<NightmareView />} />
        <Route path="*" element={<RarityView />} />
      </Routes>
    </Router>
  );
};

export default App;
