/* eslint-disable */

import React, { useState, useEffect } from "react";
import { createScope, map, transformProxies } from "./helpers";
import "../styles/IndexView.css";
import "../styles/nightmare.css";
import { Button } from "react-bootstrap";

class NightmareView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDanger: false,
      nftSelected: 1,
      traitSelected: "Skin",
      mutatedNum: 1,
      step: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    this.buttonHandler = this.buttonHandler.bind(this);
    this.getTimeDifference = this.getTimeDifference.bind(this);
    this.getTimeString = this.getTimeString.bind(this);
  }

  leadingZero(num) {
    return num < 10 && num > 0 ? "0" + num : num;
  }

  getTimeDifference(eventDate) {
    const time = Date.parse(eventDate) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    this.setState({ days, hours, minutes, seconds });
  }

  componentDidMount() {
    var date = new Date();
    var followingDay = new Date(date.getTime() + 122900005);

    this.getTimeDifference(followingDay);
    setInterval(() => this.getTimeDifference(followingDay), 1000);
  }

  buttonHandler() {
    this.setState({ showDanger: false });
  }

  setStep(step, value) {
    //Set NFT Selected
    if (step === 1) {
      this.setState({ step: 1, nftSelected: value });
    }
    if (step === 2) {
      this.setState({ step: 2, traitSelected: value });
    }
    if (step === 3) {
      var max = 8;
      var min = 0;
      var num = Math.floor(Math.random() * (max - min) + min);
      this.setState({ step: 3, mutatedNum: num });
    }
  }

  getTimeString() {
    return (
      this.leadingZero(this.state.days) +
      "d " +
      this.leadingZero(this.state.hours) +
      "h " +
      this.leadingZero(this.state.minutes) +
      "m " +
      this.leadingZero(this.state.seconds) +
      "s"
    );
  }

  skins = [
    "Pale",
    "Blue",
    "Pink",
    "Black",
    "Gold",
    "Diamond",
    "Galaxy",
    "Zombie",
  ];

  render() {
    console.log(this.state.mutatedNum);
    console.log(this.state.nftSelected);
    return (
      <div className="nightmare showDangerBody fade-in">
        {this.state.showDanger ? (
          <div className="enterBody">
            <img
              className="enterImg"
              src="https://lh3.googleusercontent.com/proxy/mqYaRJdvp9N0UDwLCBiO8OLnlAzjkQ9E_EsSx_MdvBSjI_wNoT273VoZKfnu_uzO4SiAP1mnHphAF0Nit42_EYb7YEckFkasfFeAqnYBbd0G"
            />

            <h1 className="lost">ENTER IF YOU DARE</h1>
            <div className="container">
              <section>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
                <div className="blooddrop"></div>
              </section>
            </div>

            <button className="enterBtn" onClick={this.buttonHandler}>
              ENTER
            </button>
          </div>
        ) : (
          <div className="nightmareBody fade-in">
            <img
              className="enterImg"
              src="https://lh3.googleusercontent.com/proxy/mqYaRJdvp9N0UDwLCBiO8OLnlAzjkQ9E_EsSx_MdvBSjI_wNoT273VoZKfnu_uzO4SiAP1mnHphAF0Nit42_EYb7YEckFkasfFeAqnYBbd0G"
            />
            <p className="nightmareText">
              You have been warned. This nightmare will allow you to achieve
              what you never thought possible. Pay attention to{" "}
              <span className="nightmareRule">
                Rules of the Nightmare in red.
              </span>
            </p>
            <p className="nightmareText">But at what cost?</p>

            {this.state.step === 0 && (
              <div className="fade-in nightmareFirst1">
                <h2 className="nightmareHeader">
                  Step 1: Select Vampire to offer.
                  <p className="nightmareRule"> (Must have 2+ Vampires) </p>
                </h2>

                <div className="nightmareFirst">
                  <img
                    onClick={() => this.setStep(1, 0)}
                    className="nightmareNFT"
                    src="/images/nightmare/0.png"
                  />
                  <img
                    onClick={() => this.setStep(1, 1)}
                    className="nightmareNFT"
                    src="/images/nightmare/1.png"
                  />
                  <img
                    onClick={() => this.setStep(1, 2)}
                    className="nightmareNFT"
                    src="/images/nightmare/2.png"
                  />
                  <img
                    onClick={() => this.setStep(1, 3)}
                    className="nightmareNFT"
                    src="/images/nightmare/3.png"
                  />
                  <img
                    onClick={() => this.setStep(1, 4)}
                    className="nightmareNFT"
                    src="/images/nightmare/4.png"
                  />
                  
                </div>
              </div>
            )}

            {this.state.step === 1 && (
              <div className="fade-in">
                <h2 className="nightmareHeader">
                  Step 2: Select the trait you'd like to sacricice.
                  <p className="nightmareRule">
                    Your vampire and trait must cooldown after being sacrificed.
                  </p>
                </h2>

                <div className="nightmareStep1">
                  <img
                    className="nightmareNFT"
                    src={"/images/nightmare/" + this.state.nftSelected + ".png"}
                  />
                  <div className="nightmareButtons">
                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      onClick={() => this.setStep(2, "Skin")}
                    >
                      Skin Tone
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      disabled
                    >
                      Background <span>{this.getTimeString()}</span>
                    </Button>

                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      disabled
                    >
                      Eyes <span>{this.getTimeString()}</span>
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      disabled
                    >
                      Mouth <span>{this.getTimeString()}</span>
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      disabled
                    >
                      Hair <span>{this.getTimeString()}</span>
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      disabled
                    >
                      Clothes <span>{this.getTimeString()}</span>
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      disabled
                    >
                      Hat <span>{this.getTimeString()}</span>
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      className="nightmareBtn"
                      disabled
                    >
                      Accessory <span>Already sacrificed!</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {this.state.step === 2 && (
              <div className="fade-in">
                <h2 className="nightmareHeader">
                  Step 3: Confirm odds and sacrifice
                  <p className="nightmareRule">
                    Vampires with alike traits in your wallet boost your
                    chances!
                  </p>
                </h2>

                <div className="nightmareStep1">
                  <img
                    className="nightmareNFT"
                    src={"/images/nightmare/" + this.state.nftSelected + ".png"}
                  />

                  <div>
                    <p className="nightmareText">
                      Trait selected: <span className="red">Skin</span>
                    </p>
                    <p className="nightmareText">
                      Current Skin:
                      <span className="red">
                        {" "}
                        {this.skins[this.state.nftSelected]}
                      </span>
                    </p>
                    <div className="s3Rarities">
                      <p className="nightmareText sr3Text">Rarities</p>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">Pale: Common</p>
                        <p className="nightmareText sr3RowText sr3Uncommon">
                          40%
                        </p>
                      </div>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">Blue: Common</p>
                        <p className="nightmareText sr3RowText sr3Uncommon">
                          20%
                        </p>
                      </div>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">
                          Pink: Uncommon
                        </p>
                        <p className="nightmareText sr3RowText sr3Uncommon">
                          15%
                        </p>
                      </div>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">
                          Black: Uncommon
                        </p>
                        <p className="nightmareText sr3RowText sr3Uncommon">
                          10%
                        </p>
                      </div>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">Gold: Rare</p>
                        <p className="nightmareText sr3RowText sr3Uncommon sr3Green">
                          <span>5%</span> 7.5%
                        </p>
                      </div>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">
                          Diamond: Rare
                        </p>
                        <p className="nightmareText sr3RowText sr3Uncommon">
                          5%
                        </p>
                      </div>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">
                          Galaxy: Ultra-Rare
                        </p>
                        <p className="nightmareText sr3RowText sr3Uncommon">
                          2.5%
                        </p>
                      </div>
                      <div className="s3RareRow">
                        <p className="nightmareText sr3RowText">
                          Zombie: Legendary
                        </p>
                        <p className="nightmareText sr3RowText sr3Uncommon sr3Green">
                          <span>1%</span> 1.5%
                        </p>
                      </div>

                      <p className="nightmareText sr3Text sr3Increase">
                        Your chances were increased because of the vampires in
                        your wallet!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="nightmareFinish">
                  <Button
                    variant="primary"
                    size="lg"
                    className="nightmareBtn nmSac"
                    onClick={() => this.setStep(3, "Sacrifice")}
                  >
                    Sacrifice
                  </Button>
                </div>
              </div>
            )}

            {this.state.step === 3 && (
              <div className="fade-in">
                <h2 className="nightmareHeader">
                  Step 4: View new trait mutation!
                  <p className="nightmareRule">
                    This trait now will be on cooldown. Enjoy your new trait!
                  </p>
                </h2>

                <div className="nightmareStep1">
                  <img
                    className="nightmareNFT"
                    src={"/images/nightmare/" + this.state.nftSelected + ".png"}
                  />

                  {this.state.mutatedNum <= this.state.nftSelected ? (
                    <div className="nightmareNFTBox nftFailed">
                      <p className="nightmareText">
                        Trait: Skin sacrifice failed!
                      </p>
                      <p className="nightmareText">
                        {this.skins[this.state.nftSelected]} →{" "}
                        {this.skins[this.state.mutatedNum]}
                      </p>
                    </div>
                  ) : (
                    <div className="nightmareNFTBox">
                      <p className="nightmareText">
                        Trait: Skin successfully sacrified!
                      </p>
                      <p className="nightmareText">
                        {this.skins[this.state.nftSelected]} →{" "}
                        {this.skins[this.state.mutatedNum]}
                      </p>
                    </div>
                  )}

                  <img
                    className="nightmareNFT nightmareNFTEnd"
                    src={"/images/nightmare/" + this.state.mutatedNum + ".png"}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default NightmareView;
